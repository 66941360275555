import {
    GithubAuthProvider,
    GoogleAuthProvider,
    User,
    confirmPasswordReset,
    getAuth,
    getIdToken,
    onAuthStateChanged,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
    updatePassword,
    verifyPasswordResetCode,
} from 'firebase/auth';
import {
    PasswordPolicyDocument,
    UserSignUpAlreadyInAuthProviderDocument,
    UserSignUpDocument,
    UserType,
} from '../graphql/types/graphql';
import { getUser, useUpdateUser } from './user';

import { RoutesNames } from '@/router/routesNames';
import { getUserCompanies } from '@/composables/companies';
import i18n from '@/i18n';
import { logoutCustomerly } from '@/plugins/customerly';
import moment from 'moment';
import mutation from '@/graphql/mutation';
import query from '@/graphql/query';
import { resetStore } from '@/store';
import router from '@/router';
import { useDialogsStore } from '@/store/dialogs';
import { useErrorStore } from '@/store/error';
import { useI18n } from 'vue-i18n';
import { useOnboardingStore } from '@/store/onboarding';
import { useUserStore } from '@/store/user';

export async function checkSession(callback?: any) {
    const userStore = useUserStore();
    userStore.fetching = true;
    const token = await getCurrentSession();
    if (token) {
        await getUser(() => {
            getUserCompanies();
            if (callback) {
                callback();
            }
        });
        return true;
    } else {
        userStore.fetching = false;
        return false;
    }
}

export function checkOnboarding() {
    if (router.currentRoute.value.name !== RoutesNames.confirmEmail) {
        const userStore = useUserStore();
        if (!userStore.emailVerified) {
            router.replace({ name: RoutesNames.confirmEmail });
        } else {
            const onboardingCompleted = userStore.isOnboardingCompleted;
            if (!onboardingCompleted) {
                if (useOnboardingStore().isStepOneCompleted) {
                    router.replace({ name: RoutesNames.onboarding_step_2 });
                } else {
                    router.replace({ name: RoutesNames.onboarding_step_1 });
                }
            } else {
                checkViewType();
            }
        }
    }
    return false;
}

export function checkViewType() {
    // controllare se l'utente deve accettare la privacy policy prima di mandarlo in dashboard
    if (router.currentRoute.value.name !== RoutesNames.confirmEmail) {
        const userStore = useUserStore();
        if (!userStore.privacyAccepted) {
            router.replace({ name: RoutesNames.privacy });
        } else {
            const dialogsStore = useDialogsStore();
            if (!userStore.secondTermsAccepted) {
                router.replace({ name: RoutesNames.company_terms });
                dialogsStore.termsCompany = true;
            } else {
                dialogsStore.termsCompany = false;
                if (userStore.viewTypeUser === null) {
                    signoutUser();
                } else {
                    if (userStore.viewTypeUser) {
                        router.replace({ name: RoutesNames.dashboard });
                    } else {
                        router.replace({
                            name: RoutesNames.company_dashboard,
                            query: { ...router.currentRoute.value.query },
                        });
                    }
                }
            }
        }
    }
}

export function goToDashboard() {
    const userStore = useUserStore();
    if (userStore.viewTypeUser === null) {
        signoutUser();
    } else {
        if (userStore.viewTypeUser) {
            router.replace({ name: RoutesNames.dashboard });
        } else {
            router.replace({ name: RoutesNames.company_dashboard });
        }
    }
}

export function goToOnboarding() {
    const userStore = useUserStore();
    const onboardingCompleted = userStore.isOnboardingCompleted;
    if (!onboardingCompleted) {
        if (useOnboardingStore().isStepOneCompleted) {
            router.replace({ name: RoutesNames.onboarding_step_2 });
        } else {
            router.replace({ name: RoutesNames.onboarding_step_1 });
        }
    } else {
        checkViewType();
    }
}

export async function getCurrentSession(): Promise<string | undefined> {
    return new Promise((resolve, reject) => {
        const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
            unsubscribe();
            if (user) {
                getIdToken(user).then(
                    (idToken) => {
                        resolve(idToken);
                    },
                    (error) => {
                        resolve(undefined);
                    }
                );
            } else {
                resolve(undefined);
            }
        });
    });
}

export function getPasswordPolicy() {
    const { result, loading, onError } = query(PasswordPolicyDocument);
    onError((result) => {
        console.error(result.message);
    });
    return { result, loading };
}

export function signupUser() {
    const { mutate, loading, onDone, onError } = mutation(UserSignUpDocument);
    //onError((result) => {
    //    console.error(result.message);
    //});
    return { mutate, loading, onDone, onError };
}

export function signupUserAlreadyInProvider() {
    const { mutate, loading, onDone, onError } = mutation(
        UserSignUpAlreadyInAuthProviderDocument
    );
    //onError((result) => {
    //    console.error(result.message);
    //});
    return { mutate, loading, onDone, onError };
}

export async function signinUser(email: string, password: string): Promise<boolean> {
    try {
        await signInWithEmailAndPassword(getAuth(), email, password);
        //await Auth.signIn(email, password);
        return true;
    } catch (error) {
        console.log('error signing in', error);
        // useErrorStore().setError((error as any).message);
        return false;
    }
}

export async function signoutUser(): Promise<boolean> {
    try {
        await getAuth().signOut();
        resetStore();
        logoutCustomerly();
        router.replace({ name: RoutesNames.signIn });
        return true;
    } catch (error) {
        console.log(error);
        console.log('error signing out', error);
        return false;
    }
}

export async function resetPassword(email: string): Promise<boolean> {
    try {
        await sendPasswordResetEmail(getAuth(), email);
        return true;
    } catch (error) {
        console.log('error reset password in', error);
        return false;
    }
}

export async function checkPasswordResetCode(actionCode: string): Promise<string> {
    try {
        const email = await verifyPasswordResetCode(getAuth(), actionCode);
        return email;
    } catch (error) {
        console.log('error reset password code', error);
        return '';
    }
}

export async function setNewPassword(
    actionCode: string,
    newPassword: string
): Promise<boolean> {
    try {
        await confirmPasswordReset(getAuth(), actionCode, newPassword);
        return true;
    } catch (error) {
        console.log('error setting new password', error);
        return false;
    }
}

export async function changePassword(newPassword: string): Promise<boolean> {
    try {
        const user = await getAuth().currentUser;
        if (user != null) {
            await updatePassword(user, newPassword);
            return true;
        }
        console.log('error setting new password');
        return false;
    } catch (error) {
        console.log('error setting new password', error);
        return false;
    }
}

export async function signInWithGoogle(isTalentView: boolean) {
    const provider = new GoogleAuthProvider();
    getAuth().useDeviceLanguage();

    signInWithPopup(getAuth(), provider)
        .then(async (result) => {
            await checkSocialSignupOrAccess(result.user, 'Google', isTalentView);
        })
        .catch((error) => {
            console.error(error);
            if (error.code == 'auth/account-exists-with-different-credential') {
                useErrorStore().setError(
                    i18n.global.t('existingSocialLoginError', {
                        provider: provider,
                    })
                );
            }
        });
}

export async function signInWithGitHub(isTalentView: boolean) {
    const provider = new GithubAuthProvider();
    getAuth().useDeviceLanguage();

    signInWithPopup(getAuth(), provider)
        .then(async (result) => {
            await checkSocialSignupOrAccess(result.user, 'GitHub', isTalentView);
        })
        .catch((error) => {
            console.error(error);
            if (error.code == 'auth/account-exists-with-different-credential') {
                useErrorStore().setError(
                    i18n.global.t('existingSocialLoginError', {
                        provider: provider,
                    })
                );
            }
        });
}

async function checkSocialSignupOrAccess(
    user: User,
    provider: string,
    isTalentView: boolean
) {
    const oneMinuteAgo = moment().subtract(1, 'minutes');
    const date = moment(user.metadata.creationTime);
    if (date.isSameOrAfter(oneMinuteAgo)) {
        const {
            mutate: signupWithProvider,
            onDone: onDoneSignup,
            onError: onErrorSignup,
        } = signupUserAlreadyInProvider();

        signupWithProvider({
            email: user.email ? user.email : '',
            language_id: 'ita',
        });

        onDoneSignup(async (signupResult) => {
            if (isTalentView) {
                const {
                    mutate: updateUser,
                    loading: loadingUpdate,
                    onDone: onDoneUpdate,
                } = useUpdateUser();

                if (signupResult?.data?.userSignUpAlreadyInAuthProvider?.id) {
                    await updateUser({
                        id: signupResult.data.userSignUpAlreadyInAuthProvider.id,
                        userType: UserType.Talent,
                    });
                }
            }

            await checkSession(checkOnboarding);
        });

        onErrorSignup((err) => {
            useErrorStore().setError(
                i18n.global.t('socialLoginError', {
                    provider: provider,
                })
            );
        });
    } else {
        await checkSession(checkOnboarding);
    }
}
